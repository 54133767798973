<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("periods.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-3 mb-3">
            <b-col cols="6">
              <h6 class="ssp-24">{{ $t("periods.title") }}</h6>
            </b-col>
            <b-col cols="6" class="text-right">
              <button
                class="btn btn-success btn-add ml-auto mr-4"
                @click="onTambah"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {{ $t("periods.add") }}
              </button>
            </b-col>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('periods.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <!-- <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                variant="danger"
                triggers="focus"
              >
                <b-tabs content-class="mt-3">
                  <b-tab title="Role">
                    <div class="filter-overflow">
                      <div
                        class="form-checkbox"
                        v-for="role in optionRole1"
                        :key="role.id"
                      >
                        <label class="form-radio-label">
                          <input
                            class="form-radio-item"
                            type="radio"
                            :value="role"
                            v-model="filterRole"
                          />
                          {{ role }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterRole === null }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                </b-tabs>
              </b-popover>
            </div> -->
          </div>
          <div class="table-borderless mx-3">
            <b-table
              hover
              :items="periods"
              :fields="[
                {
                  label: '#',
                  key: 'index'
                },
                {
                  label: $t('periods.headers[1]'),
                  key: 'startDate'
                },
                {
                  label: $t('periods.headers[2]'),
                  key: 'endDate'
                }
              ]"
              sort-icon-left
              show-empty
              small
              responsive="true"
              class="simple-master-table"
            >
              <!-- <template v-slot:head(selected)>
                <input type="checkbox" />
              </template>
              <template v-slot:cell(selected)="id">
                <div @click.stop>
                  <input type="checkbox" />
                </div>
              </template> -->

              <template #cell(index)="data">
                <ul class="list-inline d-inline-block" title="Farmer Name">
                  <li class="list-inline-item">
                    {{ ++data.index + (currPage - 1) * perPage }}
                  </li>
                  <br />
                </ul>
              </template>

              <template #cell(startDate)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                  title="Farmer Name"
                >
                  <li class="list-inline-item">
                    {{ dateFr(data.item.startDate, "DD MMM YYYY") }}
                  </li>
                  <br />
                </ul>
              </template>

              <template #cell(endDate)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                  title="Farmer Name"
                >
                  <li class="list-inline-item">
                    {{ dateFr(data.item.endDate, "DD MMM YYYY") }}
                  </li>
                  <br />
                </ul>
              </template>

              <!-- <template v-slot:cell(actions)="{ item }">
                <ul
                  style="width: max-content"
                  class="list-inline d-inline-block mw-100"
                >
                  <div class="action-wrapper font-12">
                    <i
                      class="ri-edit-fill ri-lg ri-mid action-btn"
                      @click="onEdit(item)"
                    ></i>
                    <i
                      class="ri-delete-back-2-fill ri-lg ri-mid action-btn"
                      @click="onDelete(item.id)"
                    ></i>
                  </div>
                </ul>
              </template> -->
            </b-table>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                <!-- 1 - 6 of {{ this.totalData }} -->
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-mptypes" centered hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ modalTitle }}</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <!-- <p class="">Modal Body with button</p> -->
        <form @submit="onSubmit">
          <!-- <BaseInput blabel="Bulan Awal" v-model></BaseInput> -->
          <BaseInput
            class="mb-0"
            type="number"
            :blabel="$t('periods.label.period')"
            :placeholder="$t('periods.placeholder.period')"
            v-model="formTambah.rangePeriode"
            :error="$v.formTambah.rangePeriode.$error"
            :feedback="$t('feedback.required')"
          />
          <small>
            {{ $t("periods.label.note") }}
          </small>
          <button type="submit" class="btn btn-primary ssp-14 w-100 my-3 py-2">
            {{ $t("global.save") }}
          </button>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Card, BaseInput } from "@/components/index";
// import Multiselect from "vue-multiselect";
// import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import { required } from "vuelidate/lib/validators";
import * as XLSX from "xlsx";
import { listPeriods } from "@/graphql/queries";
import { createPeriod, delPeriod, updatePeriod } from "@/graphql/mutations";

export default {
  components: {
    Card,
    BaseInput
    // "custom-select": Multiselect
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {
          label: "#",
          key: "index"
        },
        {
          label: "Start",
          key: "startDate"
        },
        {
          label: "End",
          key: "endDate"
        }
      ],

      periods: [],

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",

      modalTitle: "",
      isEdit: false,
      formTambah: {}
      // months: [
      //   "Januari",
      //   "Februari",
      //   "Maret",
      //   "April",
      //   "Mei",
      //   "Juni",
      //   "Juli",
      //   "Agustus",
      //   "September",
      //   "Oktober",
      //   "November",
      //   "Desember"
      // ]
    };
  },
  validations: {
    formTambah: {
      rangePeriode: { required }
    }
  },
  apollo: {
    listPeriode: {
      query: () => listPeriods,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          orderBy: "CREATED_AT",
          sortBy: "ASC"
        };
      },
      result({ data }) {
        this.periods = data.listPeriode.periods;
        this.totalPages = data.listPeriode.totalPage;
        this.totalData = data.listPeriode.total;
      },
      error(err) {
        this.catchError(err);
      }
    }
  },
  methods: {
    onTambah() {
      this.modalTitle = this.$t("periods.add");
      this.isEdit = false;
      this.resetForm();
      this.$bvModal.show("modal-mptypes");
    },
    generateTemplate() {
      const wb = XLSX.utils.book_new();
      let worksheet = XLSX.utils.json_to_sheet([{}]);
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "Kategori",
            "Nama Bahan",
            "Jumlah Terkecil",
            "Satuan Terkecil",
            "Harga / Satuan Terkecil"
          ]
        ],
        { origin: "A1" }
      );
      worksheet["!cols"] = [
        { width: 8 },
        { width: 20 },
        { width: 14 },
        { width: 14 },
        { width: 22 }
      ];
      XLSX.utils.book_append_sheet(wb, worksheet, `Sheet 1`);
      XLSX.writeFile(wb, `Template Import User.xlsx`);
    },
    // onEdit(data) {
    //   this.modalTitle = "Edit Periode";
    //   this.isEdit = true;
    //   this.resetForm();

    //   this.formTambah.id = data.id;
    //   this.formTambah.startPeriode = data.startMonth;
    //   this.formTambah.endPeriode = data.endMonth;

    //   this.$bvModal.show("modal-mptypes");
    // },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    resetForm() {
      this.formTambah = {};
      this.$v.$reset();
    },
    closeModal() {
      this.$bvModal.hide("modal-mptypes");
      this.formTambah.matpro = "";
    },
    async onSubmit(ev) {
      ev.preventDefault();
      this.$v.$touch();
      if (!this.$v.$error) {
        if (this.isEdit) {
          try {
            const response = await new Promise((resolve, reject) => {
              const editPeriods = this.$apollo.mutate({
                mutation: updatePeriod,
                variables: this.formTambah
              });
              if (resolve) {
                resolve(editPeriods);
              } else {
                reject(editPeriods);
              }
            });
            if (response) {
              this.toastAlert(this.$t("alert.edit"));
              this.closeModal();
              this.$apollo.queries.listPeriode.refetch();
            }
          } catch (err) {
            this.$swal.fire({
              icon: "error",
              title: "Error!",
              text: err.message,
              width: 400,
              confirmButtonText: "OK"
            });
          }
        } else {
          try {
            const response = await new Promise((resolve, reject) => {
              const addPeriods = this.$apollo.mutate({
                mutation: createPeriod,
                variables: {
                  rangePeriode: parseInt(this.formTambah.rangePeriode)
                }
              });
              if (resolve) {
                resolve(addPeriods);
              } else {
                reject(addPeriods);
              }
            });
            if (response) {
              this.toastAlert(this.$t("alert.save"));
              this.closeModal();
              this.$apollo.queries.listPeriode.refetch();
            }
          } catch (err) {
            this.errorAlert(err);
          }
        }
      }
    },
    onDelete(id) {
      this.deleteConfirm().then(async result => {
        if (result.isConfirmed) {
          try {
            const response = await new Promise((resolve, reject) => {
              const delPeriods = this.$apollo.mutate({
                mutation: delPeriod,
                variables: {
                  id: id
                }
              });
              if (resolve) {
                resolve(delPeriods);
              } else {
                reject(delPeriods);
              }
            });
            if (response) {
              this.toastAlert(this.$t("alert.delete"));
              this.$apollo.queries.listPeriode.refetch();
            }
          } catch (err) {
            this.errorAlert(err);
          }
        }
      });
    },
    async handleExport() {
      this.isLoading = true;
      try {
        const result = await this.$apollo.query({
          query: listPeriods,
          variables: {
            page: 1,
            limit: 9999999,
            orderBy: "CREATED_AT",
            sortBy: "ASC"
          }
        });
        const list = result.data.listPeriode.periods;
        import("@/plugins/export-excel").then(excel => {
          const tHeader = ["#", "Start Date", "End Date"];
          const filterVal = ["index", "startDate", "endDate"];
          setTimeout(() => {
            list.map((a, b) => {
              a.index = b + 1;
              a.startDate = this.dateFr(a.startDate, "DD/MM/YYYY");
              a.endDate = this.dateFr(a.endDate, "DD/MM/YYYY");
              return a;
            });
            const data = this.formatJson(filterVal, list);
            excel.export_json_to_excel({
              header: tHeader,
              data,
              filename: this.$route.name,
              autoWidth: true,
              bookType: "xlsx"
            });
          }, 1500);
          this.limit = 10;
        });
      } catch (e) {
        this.errorAlert(e);
      }
      this.isLoading = false;
    }
  },
  mounted() {}
};
</script>

<style>
/* .tbl-tanaman tr td:nth-child(2) {
  width: 80%;
} */
</style>
